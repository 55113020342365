import  React from 'react';
import PhraseCard from './PhraseCard';


const BigList = ({ latinphrases }) => {
//     const cardComponent = robots.map((user,i) => {
//         return  (<Card 
//             key={i}
//             id={robots[i].id}
//             name={robots[i].name} 
//             email={robots[i].email}
//             />
//             )     
//     })

    return (
    <div className="mw8-ns center pt4">
       {
        latinphrases?.map((user,i) => {
        return  (<PhraseCard 
            key={i}
            id={latinphrases[i].id}
            phrase={latinphrases[i].phrase} 
            translation={latinphrases[i].translation}
            />
        ); 
    })
    }
    </div>
    );
}

export default BigList;